<template>
  <LandingPageLayout>
    <div class="min-h-screen flex items-center justify-center bg-[#eee]">
      <div class="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded shadow-md">
        <img src="../assets/logo.png"  class="h-12 w-12 mx-auto block" />
        <h2 class="text-2xl font-bold text-center text-white">Affiliates Login</h2>
        <form @submit.prevent="login">
          <label class="block text-sm font-medium text-gray-300 mt-4">Email</label>
          <input
            type="email"
            autocomplete="email"
            v-model="email"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <label class="block text-sm font-medium text-gray-300 mt-4">Password</label>
          <input
            type="password"
            autocomplete="current-password"
            v-model="password"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />

          <button
            type="submit"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-12"
          >
            Login
          </button>
        </form>
        <p class="text-center text-sm text-gray-400">
          Don't have an account?
          <router-link to="/register" class="text-orange-500 hover:underline">Register here</router-link>
        </p>
        <p class="text-center text-sm text-gray-400">
          <router-link to="/dashboard/overview" class="text-orange-500 hover:underline">Dashboard</router-link>
        </p>
        <ModalDialogComponent
            :title="modalTitle"
            :message="modalMessage"
            :visible="showModal"
            @close="showModal = false"
        />
      </div>
    </div>
  </LandingPageLayout>
</template>

<script>
import InputField from '@/components/Form/InputFieldComponent.vue';
import LandingPageLayout from "@/views/LandingPageLayout.vue";
// import model from "../module/ReactiveModel";
import CryptoJS from "crypto-js";

import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import axios from "@/module/axios";
import router from "@/module/router";

var secret = "657f8b8da628ef83cf69101b6817150a";

export default {
  components: {
    LandingPageLayout,
    InputField,
    ModalDialogComponent
  },
  data() {
    return {
      email: '',
      password: '',
      showModal: false,
      modalTitle: '',
      modalMessage: ''

    }
  },
  methods: {
    // model() {
    //   return model;
    // },
    async login() {

      const payload = {
        email: this.email,
        password: this.password,
      };

      try {
        let response = await this.$axios.request({
          url: '/login/',
          method: 'POST',
          data: payload,
        });
        window.localStorage.setItem('token', response.data.token);
        // axios.config.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        if(response.data.status === 'already_logged_in'){
          window.localStorage.setItem('token', response.data.token);
          axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          this.modalTitle = 'Success';
          this.modalMessage = 'User already logged in. Session saved. ';
          this.showModal = true;
        }
        if (response.data.status === 'success') {
          window.localStorage.setItem('token', response.data.token);
          axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          this.$toast.success('Successful');
          setTimeout(() => {
            router.push('/dashboard/overview');
          }, 3000);

        }else {
          this.$toast.error('Login failed');
        }
      } catch (error) {
        console.error(error);
        // popup: network error
        this.$toast.error('Network Error');
      }
    }
  }
}
</script>

<style scoped>
/* You can add additional styles if needed */
</style>
