<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white">
      <div class="container max-w-7xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Active Banners</h2>
        <div class="mb-4 flex justify-between items-center gap-4">
          <input
              type="text"
              placeholder="Search by tracking ID"
              v-model="searchQuery"
              class="p-2 rounded bg-gradient-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full md:w-1/3"
          />
        </div>
        <!-- Header row -->
        <div class="flex bg-orange-500 text-white py-3 rounded-t-lg font-bold">
          <div class="flex-[0.5] text-center">ID</div>
          <div class="flex-[2] text-center">Tracking ID</div>
          <div class="flex-[1] text-center">User ID</div>
          <div class="flex-[1] text-center">Product ID</div>
          <div class="flex-[1] text-center">Banner ID</div>
          <div class="flex-[1] text-center">Source ID</div>
          <div class="flex-[1] text-center">Clicks</div>
          <div class="flex-[1.5] text-center">Actions</div>
        </div>
        <!-- Data rows -->
        <div
            v-for="banner in filteredBanners"
            :key="banner.id"
            class="flex items-center border-b border-gray-300 hover:bg-gray-300 transition-colors duration-200 py-3"
        >
          <div class="flex-[0.5] text-center">{{ banner.id }}</div>
          <div class="flex-[2] text-center">{{ banner.tracking_id }}</div>
          <div class="flex-[1] text-center">{{ banner.user_id }}</div>
          <div class="flex-[1] text-center">{{ banner.product_id }}</div>
          <div class="flex-[1] text-center">{{ banner.banner_id }}</div>
          <div class="flex-[1] text-center">{{ banner.source_id }}</div>
          <div class="flex-[1] text-center">{{ banner.clicks }}</div>
          <div class="flex-[1.5] flex justify-center gap-2">
            <button
                class="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                @click="viewBanner(banner)"
            >
              <span class="material-icons">visibility</span>
            </button>
            <button
                class="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                @click="deleteBanner(banner)"
            >
              <span class="material-icons">delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/2 max-w-lg mx-4">
          <h3 class="text-2xl font-semibold text-gray-800 mb-4">Banner Details</h3>
          <div class="text-lg">
            <p><strong>Tracking ID:</strong> {{ selectedBanner.tracking_id }}</p>
            <p><strong>Clicks:</strong> {{ selectedBanner.clicks }}</p>
            <!-- Add more details here -->
          </div>
          <div class="mt-4 flex justify-end">
            <button
                class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                @click="closeModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </transition>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      searchQuery: "",
      bannerList: [],
      showModal: false,
      selectedBanner: null,
    };
  },
  methods: {
    async fetchBanners() {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: '/banners',
        });
        this.bannerList = response.data;
      } catch (error) {
        console.error("Failed to fetch active banners:", error);
      }
    },
    async deleteBanner(banner) {
      try {
        await this.$axios.request({
          method: 'DELETE',
          url: `/banners/${banner.id}`,
        });
        this.bannerList = this.bannerList.filter((b) => b.id !== banner.id);
        this.$toast.success("Banner deleted successfully");
      } catch (error) {
        this.$toast.error("Failed to delete banner!");
      }
    },
    viewBanner(banner) {
      this.selectedBanner = banner;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedBanner = null;
    },
  },
  mounted() {
    this.fetchBanners();
  },
  computed: {
    filteredBanners() {
      return this.bannerList.filter((banner) =>
          banner.tracking_id.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>

