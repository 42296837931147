<template>
  <router-view></router-view>
</template>

<script>
import 'material-design-icons/iconfont/material-icons.css';

export default {
  data() {
    return {
      currentView: 'LoginPage',
      brandName: 'System', // Default value
    };
  },
  components: {
    // Register components if needed
  },
  methods: {
    showLogin() {
      this.currentView = 'LoginPage';
    },
    showRegister() {
      this.currentView = 'RegisterPage';
    },
    showDashboard() {
      this.currentView = 'DashboardPage';
    },
    async getWhitelabelSettings() {
      try {
        let response = await this.$axios.request({
          method: "GET",
          url: "/dashboard/settings/whitelabel",
        });
        // Handle the response if needed
        console.log(response);
        this.brandName = response.data[0].brand_name;
      } catch (error) {
        console.error("Error fetching whitelabel settings:", error);
      }
    },
  },
  watch: {
    // Watch for changes to brandName and update the title accordingly
    brandName(newBrandName) {
      document.title = newBrandName;
    },
  },
  created() {
    this.getWhitelabelSettings(); // Fetch whitelabel settings when the component is created
  },
};
</script>
