<template>
  <DashboardLayout>
    <div class="flex justify-center rounded-2xl items-center drop-shadow-xl  bg-gray-100 max-w-lg mx-auto mt-6">
      <div class="container max-w-lg bg-[#eeeeee] text-black p-6 rounded overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-12 text-center">Create User</h2>

        <div class="mb-3">
          <!-- Progress Indicator -->
          <div class="flex justify-between items-center mb-4">
            <div class="flex-1 h-2 bg-gray-500 rounded" :class="step >= 1 ? 'bg-orange-500' : ''"></div>
            <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 2 ? 'bg-orange-500' : ''"></div>
            <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 3 ? 'bg-orange-500' : ''"></div>
          </div>
        </div>

        <form v-if="!submitted" @submit.prevent="handleStep" class="space-y-4">
          <!-- Step 1 -->
          <div v-if="step === 1">
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="email">Email</label>
              <input
                  id="email"
                  v-model="newUser.email"
                  type="text"
                  placeholder="Enter email"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="password">Password</label>
              <input
                  id="password"
                  v-model="newUser.password"
                  type="password"
                  placeholder="Enter password"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="confirmPassword">Confirm Password</label>
              <input
                  id="confirmPassword"
                  v-model="newUser.confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="group">User Group</label>
              <select
                  id="group"
                  v-model="newUser.group"
                  class="p-2 rounded bg-form-100 placeholder-gray-400 text-black focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              >
                <option value="">Select group</option>
                <option v-for="group in userGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
              </select>
            </div>
          </div>

          <!-- Step 2 -->
          <div v-if="step === 2">
            <div>
              <label class="block text-admin-200 text-admin-200 font-mono" for="firstName">First Name</label>
              <input
                  id="firstName"
                  v-model="newUser.firstName"
                  type="text"
                  placeholder="Enter first name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="lastName">Last Name</label>
              <input
                  id="lastName"
                  v-model="newUser.lastName"
                  type="text"
                  placeholder="Enter last name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="address">Address</label>
              <input
                  id="address"
                  v-model="newUser.address"
                  type="text"
                  placeholder="Enter address"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="city">City</label>
              <input
                  id="city"
                  v-model="newUser.city"
                  type="text"
                  placeholder="Enter city"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="postalCode">Postal Code</label>
              <input
                  id="postalCode"
                  v-model="newUser.postalCode"
                  type="text"
                  placeholder="Enter postal code"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="country">Country</label>
              <input
                  id="country"
                  v-model="newUser.country"
                  type="text"
                  placeholder="Enter country"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
          </div>

          <!-- Step 3 -->
          <div v-if="step === 3">
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="companyName">Company Name</label>
              <input
                  id="companyName"
                  v-model="newUser.companyName"
                  type="text"
                  placeholder="Enter company name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="websiteURL">Website URL</label>
              <input
                  id="websiteURL"
                  v-model="newUser.websiteURL"
                  type="text"
                  placeholder="Enter website URL"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="brands">Brands</label>
              <input
                  id="brands"
                  v-model="newUser.brands"
                  type="text"
                  placeholder="Enter brands"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-between mt-4">
            <ButtonComponent v-if="step > 1" @click="goBack" class="bg-black text-white hover:bg-white hover:text-black mr-4">Back</ButtonComponent>
            <ButtonComponent v-if="step < 3" type="submit" class="bg-orange-500 text-white">Next</ButtonComponent>
            <ButtonComponent v-if="step === 3" type="submit" @click="submitForm" class="bg-orange-500 text-white">Create User</ButtonComponent>
          </div>
        </form>

        <!-- Success Page -->
        <div v-if="submitted && success" class="mt-4 text-center">
          <h3 class="text-xl text-green-600 font-bold mb-12">User Created Successfully!</h3>
          <p class="mb-4">The user has been created.</p>
          <ButtonComponent @click="resetForm" class="bg-orange-500 text-white">Create Another User</ButtonComponent>
        </div>

        <!-- Failure Page -->
        <div v-if="submitted && !success" class="mt-4 text-center">
          <h3 class="text-xl text-red-600 font-bold mb-4">User Creation Failed!</h3>
          <p class="mb-4">There was an error creating the user. Please try again.</p>
          <ButtonComponent @click="resetForm" class="bg-orange-500 text-white">Try Again</ButtonComponent>
        </div>

        <!-- Error dialog -->
        <ModalDialogComponent
            :title="modalTitle"
            :message="modalMessage"
            :visible="showModal"
            @close="showModal = false"
        />



      </div>



    </div>


  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import CryptoJS from "crypto-js";


let secret = "657f8b8da628ef83cf69101b6817150a";
export default {
  components: {ModalDialogComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      step: 1,
      submitted: false,
      success: false,
      newUser: {
        email: "",
        password: "",
        confirmPassword: "",
        group: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        companyName: "",
        websiteURL: "",
        brands: "",
        email_hash: "",
      },
      userGroups: [],
      showModal: false,
      modalMessage: '',
      modalTitle: '',
    };
  },
  methods: {
    async getAllUserGroups() {
      try {
        let response = await this.$axios.request({
          url: '/usergroup/',
          method: 'GET',
        });
        this.userGroups = response.data.user_groups;
        console.log('User groups:', this.userGroups);
      } catch (error) {
        console.error('There was an error fetching user groups:', error);
      }
    },

    handleStep() {
      if (this.step === 1) {
        if (this.newUser.password !== this.newUser.confirmPassword) {
          this.$toast.error('Passwords do not match!');
          // this.showModal = true;
          // this.modalTitle = "Error";
          // this.modalMessage = "Passwords do not match!";
          return;
        }
        this.step++;
      } else if (this.step === 2) {
        console.log('Step 2 data:', {
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          address: this.newUser.address,
          city: this.newUser.city,
          postalCode: this.newUser.postalCode,
          country: this.newUser.country,
        });

        this.step++;
      } else if (this.step === 3) {

        console.log('Step 3 data:', {
          companyName: this.newUser.companyName,
          websiteURL: this.newUser.websiteURL,
          brands: this.newUser.brands,
        });
        // Step 3: Submit registration
        this.submitForm();
      }
    },
    goBack() {
      if (this.step > 1) {
        --this.step;
      }
    },
    submitForm() {
      console.log('Submitting form:', this.newUser);

      const payload = {
        email: this.newUser.email,
        password: this.newUser.password,
        usergroup_id: this.newUser.group,
        first_name: this.newUser.firstName,
        last_name: this.newUser.lastName,
        address: this.newUser.address,
        city: this.newUser.city,
        postal_code: this.newUser.postalCode,
        country: this.newUser.country,
        company_name: this.newUser.companyName,
        website_url: this.newUser.websiteURL,
        brands: this.newUser.brands,
        email_hash: CryptoJS.HmacSHA256(this.newUser.email, secret).toString()
      };

      console.log(payload);

      this.$axios.post('/register', payload)
          .then(response => {
            this.success = true;
            this.submitted = true;
          })
          .catch(error => {
            this.success = false;
            this.submitted = true;
            console.error('There was an error creating the user:', error);
          });
    },
    resetForm() {
      this.step = 1;
      this.submitted = false;
      this.success = false;
      this.newUser = {
        email: "",
        password: "",
        confirmPassword: "",
        group: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        companyName: "",
        websiteURL: "",
        brands: "",
        email_hash: "",
      };
    },
  },
  mounted() {
    this.getAllUserGroups();
  },
};
</script>

<style scoped>

.container {
  max-width: 600px;
}

/**

select option:checked {
  background-color: #fff;
  color: white;
}

select:focus option {
  background-color: #000;
  color: white;
}

**/

</style>
