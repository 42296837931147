<template>
  <div class=" flex flex-col bg-gray-800 text-white">
    <SidebarHeader :isCollapsed="isCollapsed" @toggle-sidebar="toggleSidebar" />
    <div class="flex-grow flex flex-col" v-if="!isCollapsed">
      <div class="flex-grow overflow-y-auto">
        <ul class="space-y-3">
          <SidebarItem
              title="Dashboard"
              icon="dashboard"
              :sub-items="[{ name: 'Overview', path: '/dashboard/overview' }]"
              :isOpen="isOpen.dashboard"
              @toggle="toggleSubMenu('dashboard')"
              @menu-selected="changeView"
          />

          <SidebarItem
              title="Users"
              icon="people"
              :sub-items="[
                { name: 'Create User', path: '/dashboard/users/create' },
                { name: 'Manage Users', path: '/dashboard/users' },
                { name: 'User Groups', path: '/dashboard/users/groups' },
                // { name: 'Permissions', path: '/dashboard/users/permissions' }
              ]"
              :isOpen="isOpen.users"
              @toggle="toggleSubMenu('users')"
              @menu-selected="changeView"
          />
          <SidebarItem
            title="Brands"
            icon="branding_watermark"
            :sub-items="[
              { name: 'Create Brand', path: '/dashboard/brands/create' },
              { name: 'Manage Brands', path: '/dashboard/brands/manage' }
            ]"
            :isOpen="isOpen.brands"
            @toggle="toggleSubMenu('brands')"
            @menu-selected="changeView"
          />

          <SidebarItem
              title="Affiliates"
              icon="work"
              :sub-items="[
                { name: 'Manage Affiliates', path: '/dashboard/affiliates/manage' },
                { name: 'Pending Affiliates', path: '/dashboard/affiliates/pending' },
                // { name: 'Affiliate Stats', path: '/dashboard/affiliates/stats' }
              ]"
              :isOpen="isOpen.affiliates"
              @toggle="toggleSubMenu('affiliates')"
              @menu-selected="changeView"
          />

          <SidebarItem
              title="Media"
              icon="photo_library"
              :sub-items="[
                { name: 'Upload Media', path: '/dashboard/media/upload' },
                { name: 'Media Library', path: '/dashboard/media/library' },
                { name: 'Active Banners', path: '/dashboard/media/active' },
                { name: 'Banner Generator', path: '/dashboard/media/banners' }
              ]"
              :isOpen="isOpen.media"
              @toggle="toggleSubMenu('media')"
              @menu-selected="changeView"
          />

          <SidebarItem
              title="Commission"
              icon="attach_money"
              :sub-items="[
                //{ name: 'Manage Commissions', path: '/dashboard/commission/manage' },
                //{ name: 'Add new Commission', path: '/dashboard/commission/create' },
                { name: 'Manage Contracts', path: '/dashboard/commission/contracts/manage' },
                { name: 'Add new Contracts', path: '/dashboard/commission/contracts/create' }
              ]"
              :isOpen="isOpen.commission"
              @toggle="toggleSubMenu('commission')"
              @menu-selected="changeView"
          />

          <SidebarItem
              title="Sources"
              icon="location_on"
              :sub-items="[
                  { name: 'Manage Sources', path: '/dashboard/sources/manage' },
                  { name: 'Add Sources', path: '/dashboard/sources/add' }
                ]"
              :isOpen="isOpen.sources"
              @toggle="toggleSubMenu('sources')"
              @menu-selected="changeView"
          />

          <SidebarItem
              title="Products"
              icon="shopping_cart"
              :sub-items="[
                { name: 'Add Product', path: '/dashboard/products/create' },
                { name: 'Manage Products', path: '/dashboard/products/manage' }
              ]"
              :isOpen="isOpen.product"
              @toggle="toggleSubMenu('product')"
              @menu-selected="changeView"
          />

          <!-- <SidebarItem
              title="Reports"
              icon="assessment"
              :sub-items="[
                { name: 'Monthly Reports', path: '/dashboard/reports/monthly' },
                { name: 'Custom Reports', path: '/dashboard/reports/custom' }
              ]"
              :isOpen="isOpen.reports"
              @toggle="toggleSubMenu('reports')"
              @menu-selected="changeView"
          /> -->

          <!-- <SidebarItem
              title="Payouts"
              icon="payment"
              :sub-items="[
                { name: 'View Payouts', path: '/dashboard/payouts/view' },
                { name: 'Payout History', path: '/dashboard/payouts/history' }
              ]"
              :isOpen="isOpen.payouts"
              @toggle="toggleSubMenu('payouts')"
              @menu-selected="changeView"
          /> -->

          <SidebarItem
              title="Settings"
              icon="settings"
              :sub-items="[
                  { name: 'Profile', path: '/dashboard/profile' },
                  { name: 'Whitelabel', path: '/dashboard/settings/whitelabel' }
                ]"
              :isOpen="isOpen.settings"
              @toggle="toggleSubMenu('settings')"
              @menu-selected="changeView"
          />
        </ul>
      </div>
      <SidebarFooter :isCollapsed="isCollapsed" />
    </div>
  </div>
</template>

<script>
import SidebarItem from '@/components/Admin/Sidebar/SidebarItem.vue';
import SidebarHeader from '@/components/Admin/Sidebar/SidebarHeader.vue';
import SidebarFooter from '@/components/Admin/Sidebar/SidebarFooter.vue';


export default {
  components: {
    SidebarHeader,
    SidebarItem,
    SidebarFooter,
  },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCollapsed: false,
      isOpen: {
        dashboard: false,
        users: false,
        settings: false,
        affiliates: false,
        media: false,
        commission: false,
        //reports: false,
        //payouts: false,
        sources: false,
        product: false,
        brands: false

      },
    };
  },
  methods: {
    toggleSidebar() {
      let handler = !this.isCollapsed;
      this.$emit('toggle-sidebar', handler);
      this.isCollapsed = !this.isCollapsed;
    },
    toggleSubMenu(menu) {
      for (const key in this.isOpen) {
        this.isOpen[key] = false;
      }
      this.isOpen[menu] = !this.isOpen[menu];
    },
    changeView(view) {
      console.log('Changing view to(sidebar component)', view);
      this.$emit('menu-selected', view);
    },
  },
  computed: {
  }
};
</script>
<style scoped>
/* Sidebar content grows to fill remaining space */
.flex-grow {
  flex-grow: 1;
}


/* Make sidebar content scrollable if needed */
.overflow-y-auto {
  overflow-y: auto; /* Allow scrolling if content is longer than the sidebar */
}

/* Optional styling for sticky header or footer */
.sticky {
  position: sticky;
}
</style>