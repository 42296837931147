<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto mt-8">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Add a New Contract</h2>
        <form>

          <div class="mt-6 bg-gray-200 p-4 rounded-lg shadow-md mb-6">
            <h3 class="text-xl text-orange-500 font-semibold mb-4 text-center">Client Information</h3>
            <div class="text-left text-lg text-gray-700">
              <p><strong class="text-admin-200">Client ID:</strong> <span class="text-black">{{ clientId }}</span></p>
              <p><strong class="text-admin-200">Client Name:</strong> <span class="text-black">{{ clientName }}</span></p>
              <p><strong class="text-admin-200">Company Name:</strong> <span class="text-black">{{ clientCompanyName }}</span></p>
            </div>
          </div>
<!-- 
          <h3 class="text-xl text-orange-500 font-semibold mb-2">Select Commission</h3>
          <div class="mb-4">
            <select v-model="selectedCommission" @change="populateContractFields" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedCommission}">
              <option value="">Select Commission</option>
              <option v-for="commission in commissions" :key="commission.id" :value="commission.id">{{ commission.name }}</option>
            </select>
          </div> -->

          <h3 class="text-xl text-orange-500 font-semibold mb-2">Contract Details</h3>
          <div class="mb-4">
            <input
                type="text"
                v-model="contractName"
                placeholder="Contract Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{'border-red-500': !contractName}"
                required
            />
          </div>
          <div class="mb-4">
            <select v-model="selectedBrand" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedBrand}">
              <option value="">Select Brand</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
            </select>
          </div>
          <div class="mb-4">
            <select v-model="selectedProduct" :disabled="loadingProducts" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500">
              <option value="">Select Product</option>
              <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
            </select>
            <div v-if="loadingProducts" class="text-sm text-gray-500">Loading products...</div>
          </div>
          <!-- Affiliate Selection -->
          <h3 class="text-xl text-orange-500 font-semibold mb-2">Select Affiliate</h3>
          <div class="mb-4">
            <select v-model="selectedAffiliate" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedAffiliate}">
              <option value="">Select Affiliate</option>
              <option v-for="affiliate in affiliates" :key="affiliate.id" :value="affiliate">{{ affiliate.name }}</option>
            </select>
            <div v-if="loadingAffiliates" class="text-sm text-gray-500">Loading affiliates...</div>
          </div>


          <div  v-show="selectedAffiliate" class="mt-6 bg-gray-200 p-4 rounded-lg shadow-md mb-4">
            <h3 class="text-xl text-orange-500 font-semibold mb-4 text-center">Affiliate Information</h3>
            <div class="text-left text-lg text-gray-700">
              <p><strong class="text-admin-200">Affiliate ID:</strong> <span class="text-black">{{ selectedAffiliate.id }}</span></p>
              <p><strong class="text-admin-200">Affiliate Name:</strong> <span class="text-black">{{ selectedAffiliate.name }}</span></p>
            </div>
          </div>



          <h3 class="text-xl text-orange-500 font-semibold mb-2">Min. Payout Threshold</h3>
          <div class="mb-4">
            <input
                type="text"
                v-model="minPayout"
                placeholder="Min. Payout Amount (€)"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>
          <div class="mb-4">
            <select v-model="paymentTerm" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !revenueModel}">
              <option value="" disabled>Payment Term</option>
              <option value="weekly">Weekly</option>
              <option value="biweekly">Biweekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div class="flex justify-center mt-6">
            <ButtonComponent
                class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"

            >
              <span @click="handleAddContract">Add Contract</span>
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      minPayout: '',
      paymentTerm:'',
      contractName: '',
      clientId: '',
      clientName: '',
      clientCompanyName: '',
      selectedBrand: "",
      selectedProduct: "",
      selectedAffiliate: "",
      revenueModel: '',
      flatPercentage: null,
      revShareMin: null,
      revShareMax: null,
      revSharePercentage: null,
      cpaModel: '',
      playerLevel: null,
      selectedCommission: '',
      commissions : [],
      brands: [],
      products: [],
      affiliates:[],
      filteredProducts: [],
      filteredAffiliates: [],
      loadingProducts: false,
      loadingAffiliates: false,
    };
  },
  computed: {
    isFormValid() {
      return this.contractName && this.selectedBrand && this.selectedProduct && this.revenueModel && this.isRevenueFieldsValid() && this.cpaModel && this.playerLevel;
    },
  },
  // watch: {
  //   selectedBrand(newBrand) {
  //     this.filterProductsByBrand(newBrand);
  //   }
  // },
  methods: {
    async getContract(){
      let response = await this.$axios.request({
        method: 'GET',
        url: '/contracts',
      });

      this.clientId = response.data.current_user.id;
      this.clientName = response.data.current_user.first_name + ' ' + response.data.user.last_name;
      this.clientCompanyName = response.data.current_user.company_name;

      this.commissions = response.data.commissions.map(commission => ({
        id: commission.id,
        name: commission.commission_name,
        brandId: commission.brand_id,
        productId: commission.product_id
      }));

      console.log('get contract response', this.commissions);
      console.log('Response', response.data);
      console.log('Response products', response.data.products);
      console.log('typeof this.products', this.products);
      console.log('this.products', this.products);
      console.log('000');
      this.brands = response.data.brands.map(brand => ({
        id: brand.id,
        name: brand.name
      }));

      this.products = response.data.products;

      console.log('Response', response.data);
      console.log('Response products', response.data.products);
      console.log('typeof this.products', this.products);
      console.log('this.products', this.products);


    },
    async fetchBrands() {
      try {
        const response = await this.$axios.get("/brands");
        this.brands = response.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProducts() {
      try {
        const response = await this.$axios.request({
          method: "GET",
          url: "/products",
        });
        this.products = response.data.products;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async fetchAffiliates(){
      let response = await this.$axios.get('/affiliates/manage');



      this.affiliates = response.data.users.map((aff) => {
        return {
          id: aff.id,
          name: aff.first_name + ' ' + aff.last_name + ' • ' + aff.email
        };
      });

      console.log('Fetching affiliates..', response);

    },
    async handleAddContract() {
      // const payload = {
      //   contractName: this.contractName,
      //   selectedBrand: this.selectedBrand,
      //   selectedProduct: this.selectedProduct,
      //   selectedAffiliate: this.selectedAffiliate,
      //   minPayout: this.minPayout,
      //   paymentTerm: this.paymentTerm,
      //   revenueModel: this.revenueModel,
      //   flatPercentage: this.flatPercentage,
      //   revShareMin: this.revShareMin,
      //   revShareMax: this.revShareMax,
      //   revSharePercentage: this.revSharePercentage,
      //   cpaModel: this.cpaModel,
      //   playerLevel: this.playerLevel,
      // };
      const payload = {
       // selectedCommission:  this.selectedCommission,
        contractName: this.contractName,
        selectedBrand: this.selectedBrand,
        selectedProduct: this.selectedProduct,
        selectedAffiliate: this.selectedAffiliate.id,
        minPayout: this.minPayout,
        paymentTerm: this.paymentTerm,
      }

      console.log(payload);


      let response = await this.$axios.request({
        method: 'POST',
        url: '/contracts',
        data: payload
      });

      if(response.status === 200){
        this.$toast.success('Contract added successfully');
      } else {
        this.$toast.error('Failed to add contract');
      }



      //let response = await this.$axios.post('/dashboard/contracts');
    },
    populateContractFields() {
      if (this.selectedCommission) {
        this.selectedBrand = this.commissions[this.selectedCommission - 1].brandId;
        this.selectedProduct = this.commissions[this.selectedCommission - 1].productId;
      }
    },
    // filterProductsByBrand(brandId) {
    //   this.filteredProducts = this.products.filter(product => product.brandId === brandId);
    // },
    isRevenueFieldsValid() {
      if (this.revenueModel === "flat") {
        return this.flatPercentage;
      }
      if (this.revenueModel === "rev_share") {
        return this.revShareMin && this.revShareMax && this.revSharePercentage;
      }
      return false;
    }
  },
  mounted(){
    this.getContract();
    this.fetchAffiliates();
  }
};
</script>
