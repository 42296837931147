<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold text-orange-500">Manage Sources</h2>
            </div>
            <div class="mt-12">
              <div class="flex flex-col bg-gray-200 rounded-lg overflow-hidden">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">Name</div>
                  <div class="py-3 px-4 font-semibold flex-1">Affiliate</div>
                  <div class="py-3 px-4 font-semibold flex-1">URL</div>
                  <div class="py-3 px-4 font-semibold flex-none">Actions</div>
                </div>
                <div v-for="source in sources" :key="source.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ source.name }}</div>
                  <div class="py-3 px-4 flex-1">{{ affiliates[source.user_id] }}</div>
                  <div class="py-3 px-4 flex-1">{{ source.url }}</div>
                  <div class="py-3 px-4 flex-none">
                    <button
                        @click="editSource(source)"
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                    >
                      <span class="material-icons">edit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for editing sources -->
      <transition name="fade">
        <div v-if="isModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Edit Source</h2>
            <form>
              <div class="mb-4">
                <input
                    type="text"
                    v-model="newSource.name"
                    placeholder="Source Name"
                    class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-4">
                <input
                    type="text"
                    v-model="newSource.url"
                    placeholder="Source URL"
                    class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="flex justify-center gap-4 mt-6">
                <button
                    type="submit"
                    @click="updateSource"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                >
                  Update Source
                </button>
                <button
                    @click="closeModal"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none mt-4"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      sources: [],
      affiliates: [],
      isModalOpen: false,
      newSource: {
        id: null,
        name: '',
        url: '',
      },
    };
  },
  methods: {
    editSource(source) {
      this.isModalOpen = true;
      this.newSource = { ...source };
    },
    async updateSource() {
      try {
        const response = await this.$axios.request({
        url: `/source/index/${this.newSource.id}`,
        method: 'PUT',
        data: this.newSource
      });

        const updatedSource = response.data;
        const index = this.sources.findIndex(source => source.id === updatedSource.id);
        if (index !== -1) {
          this.sources.splice(index, 1, updatedSource);
        }
        this.$toast.success('Source updated successfully!');
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed to update source.');
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    resetForm() {
      this.newSource = {
        id: null,
        name: '',
        url: '',
      };
    },
    async fetchSources() {
      try {
        const response = await this.$axios.get('/source');
        this.sources = response.data.sources;
        this.affiliates = response.data.users;
      } catch (error) {
        console.error('Error fetching sources:', error);
      }
    },
  },
  mounted() {
    this.fetchSources();
  }
};
</script>
