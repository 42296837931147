<template>
  <div class="min-h-screen flex items-center justify-center bg-[#eee]">
    <div class="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded shadow-md">
      <img src="../assets/logo.png" alt="EsoftAffiliates logo" class="h-12 w-12 mx-auto block" />
      <h2 class="text-2xl text-white font-bold text-center">Register</h2>

      <!-- Progress Indicator -->
      <div class="flex justify-between items-center mb-4">
        <div class="flex-1 h-2 bg-gray-500 rounded" :class="step >= 1 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 2 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 3 ? 'bg-orange-500' : ''"></div>
        <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step === 4 ? 'bg-orange-500' : ''"></div>
      </div>

      <form @submit.prevent="handleStep">
        <!-- Step 1: Account Details -->
        <div v-if="step === 1">
          <!-- Email -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Email</label>
          <input
            type="email"
            autocomplete="email"
            v-model="email"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Password -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Password</label>
          <input
            type="password"
            autocomplete="new-password"
            v-model="password"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Confirm Password -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Confirm Password</label>
          <input
            type="password"
            autocomplete="new-password"
            v-model="confirmPassword"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
        </div>

        <!-- Step 2: Personal Information -->
        <div v-if="step === 2">
          <!-- First Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">First Name</label>
          <input
            type="text"
            v-model="firstName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Last Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Last Name</label>
          <input
            type="text"
            v-model="lastName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Address -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Address</label>
          <input
            type="text"
            v-model="address"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- City -->
          <label class="block text-sm font-medium text-gray-300 mt-4">City</label>
          <input
            type="text"
            v-model="city"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Postal Code -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Postal Code</label>
          <input
            type="text"
            v-model="postalCode"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Country -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Country</label>
          <input
            type="text"
            v-model="country"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
        </div>


        <!-- Step 3: Company Information -->
        <div v-if="step === 3">
          <!-- Company Name -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Company Name</label>
          <input
            type="text"
            v-model="companyName"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Website URL -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Website URL</label>
          <input
            type="text"
            v-model="websiteURL"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
          <!-- Brands -->
          <label class="block text-sm font-medium text-gray-300 mt-4">Brands</label>
          <input
            type="text"
            v-model="brands"
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
            required
          />
        </div>

        <!-- Step 4: Email Verification -->
        <div v-if="step === 4" class="text-center text-white">
          <p class="mb-3">Please verify your email to complete registration.</p>
          <button class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" @click.prevent="resendVerification">Resend Verification Email</button>
        </div>

        <!-- Step Navigation Buttons -->
        <div v-if="step < 4" class="flex justify-between mt-4">
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" v-if="step > 1" @click.prevent="step--">Back</button>
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" v-if="step < 3" type="submit">Next</button>
          <button class="w-full mx-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4" v-if="step === 3" type="submit">Submit</button>
        </div>
      </form>

      <p class="text-center text-sm text-gray-400">
        Already have an account?
        <router-link to="/login" class="text-orange-500 hover:underline">Login here</router-link>
      </p>

      <!-- Error dialog -->
      <ModalDialogComponent
          :title="modalTitle"
          :message="modalMessage"
          :visible="showModal"
          @close="showModal = false"
      />

    </div>
  </div>
</template>

<script>
import InputField from '@/components/Form/InputFieldComponent.vue';
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import CryptoJS from 'crypto-js';


let secret = "657f8b8da628ef83cf69101b6817150a";

export default {
  components: {
    InputField,
    ButtonComponent,
    ModalDialogComponent,
  },
  data() {
    return {
      step: 1,
      email: '',
      password: '',
      confirmPassword: '',
      companyName: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      websiteURL: '',
      brands: '',
      showModal: false,
      modalMessage: '',
      modalTitle: '',
    };
  },
  methods:
  {
    handleStep() {
      if (this.step === 1) {
        if (this.password !== this.confirmPassword) {
          this.showModal = true;
          this.modalTitle = "Error";
          this.modalMessage = "Passwords do not match!";
          return;
        }
        this.step++;
      } else if (this.step === 2) {
        console.log('Step 2 data:', {
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
        });

        this.step++;
      } else if (this.step === 3) {

        console.log('Step 3 data:', {
          companyName: this.companyName,
          websiteURL: this.websiteURL,
          brands: this.brands,
        });
        // Step 3: Submit registration
        this.submitRegistration();
      }
    },
    async submitRegistration() {
      // Registration logic here (e.g., API call)
      const payload = {
        email: this.email,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        address: this.address,
        city: this.city,
        postal_code: this.postalCode,
        country: this.country,
        company_name: this.companyName,
        website_url: this.websiteURL,
        brands: this.brands,
        email_hash: CryptoJS.HmacSHA256(this.email, secret).toString(),

      };

      console.log('Submitting registration with payload:', {
        payload
      });


      try {
        let response = await this.$axios.request({
          url: '/register/',
          method: 'POST',
          data: payload,
        });
        this.$toast.success('Registration successful!');
      } catch (error) {
        this.$toast.error('Registration failed.');
      }

      this.step = 4;
    },
    resendVerification() {
      // Resend email verification logic
      this.$toast.success('Verification email sent!');
    },
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
