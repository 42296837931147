<template>
  <DashboardLayout>
    <div class="min-h-screen bg-gray-100 flex items-center justify-center">
      <div class="bg-white shadow-md rounded-lg max-w-4xl w-full p-6">
        <!-- Header -->
        <div class="flex justify-between items-center border-b pb-4 mb-6">
          <h1 class="text-2xl font-bold text-gray-700">My Profile</h1>
          <button
              class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition"
              @click="editMode = !editMode"
          >
            {{ editMode ? 'Cancel' : 'Edit Profile' }}
          </button>
        </div>


        <!-- Tabs Navigation -->
        <div class="flex space-x-4 border-b pb-2">
          <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="activeTab = index"
              :class="{
            'text-orange-500 border-b-2 border-orange-500': activeTab === index,
            'text-gray-600': activeTab !== index,
          }"
              class="pb-2 text-lg font-semibold transition hover:text-orange-500 focus:outline-none"
          >
            {{ tab }}
          </button>
        </div>


        <!-- Tabs Content -->
        <div class="mt-6">
          <!-- Profile Tab -->
          <div v-show="activeTab === 0">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- Avatar Section -->
              <div class="flex flex-col items-center">
                <div class="relative">
                  <img
                      :src="user.avatar || 'https://via.placeholder.com/150'"
                      alt="Profile Picture"
                      class="w-32 h-32 rounded-full border-4 border-gray-200"
                  />
                  <div
                      v-if="editMode"
                      class="absolute bottom-0 right-0 bg-orange-500 p-2 rounded-full cursor-pointer"
                      @click="changeAvatar"
                  >
                    <span class="material-icons text-white">edit</span>
                  </div>
                </div>
                <h2 class="text-xl font-semibold mt-4 text-gray-700">{{ user.name }}</h2>
                <p class="text-gray-500">{{ user.email }}</p>
              </div>

              <!-- Profile Form -->
              <div>
                <form @submit.prevent="saveProfile">
                  <!-- Name -->
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                        id="name"
                        v-model="user.name"
                        type="text"
                        class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                        :disabled="!editMode"
                    />
                  </div>

                  <!-- Email -->
                  <div class="mb-4">
                    <label for="email" class="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                        id="email"
                        v-model="user.email"
                        type="email"
                        class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                        :disabled="!editMode"
                    />
                  </div>

                  <!-- Phone -->
                  <div class="mb-4">
                    <label for="phone" class="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <input
                        id="phone"
                        v-model="user.phone"
                        type="tel"
                        class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                        :disabled="!editMode"
                    />
                  </div>
                  <!-- Show User Groups -->
                  <div class="mb-4">
                    <label for="group" class="block text-sm font-medium text-gray-700">
                      User Group
                    </label>
                    <div class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500">
                      <template v-if="Array.isArray(user.group)">
                        <div v-for="(group, index) in user.group" :key="index">
                          <span class="px-2 py-1 bg-admin-200 text-gray-700 rounded-md mr-2">
                            {{ group }}
                          </span>
                        </div>
                      </template>
                      <template v-else>
                        <span class="px-2 py-1 bg-admin-200 text-admin-100 rounded-md">
                          {{ user.group }}
                        </span>
                      </template>
                    </div>
                  </div>



                  <!-- Save Button -->
                  <div v-if="editMode" class="flex justify-end">
                    <button
                        type="submit"
                        class="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- Account Tab -->
          <div v-show="activeTab === 1">
            <h2 class="text-xl font-bold mb-4">Account Settings</h2>
            <p class="text-gray-600">
              Manage your account settings like changing your password, setting up two-factor authentication, and more.
            </p>
            <div class="mt-4">
              <div>
                <label for="username" class="block">Current Password:</label>
                <input
                    type="password"
                    id="current_password"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <label for="password" class="block">Password:</label>
                <input
                    type="password"
                    id="password"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
              <div class="mt-4">
                <label for="confirm_password" class="block">Confirm Password:</label>
                <input
                    type="password"
                    id="confirm_password"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
              <div class="mt-4 flex justify-end gap-4">
                <button
                    class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Save Changes
                </button>
                <button
                    class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                >
                  Close
                </button>
              </div>
            </div>
              <!-- Placeholder for account 2fa -->
          </div>
          <div v-show="activeTab === 2">
            <h2 class="text-xl font-bold mb-4">Payment Settings</h2>
            <p class="text-gray-600">
              Manage your payment settings like adding a new card, updating your billing address, and more.
            </p>
            <div class="mt-4">
              <div>
                <label for="bank_name" class="block">Bank Name:</label>
                <input
                    type="text"
                    id="bank_name"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="iban" class="block">IBAN:</label>
              <input
                  type="text"
                  id="iban"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
            </div>
            <div class="mt-4">
              <label for="swift_code" class="block">SWIFT Code:</label>
              <input
                  type="text"
                  id="swift_code"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
              >
            </div>
            <div class="mt-4">
              <label for="account_holder_name" class="block">Account Number:</label>
              <input
                  type="text"
                  id="account_holder_name"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
              >
            </div>
            <div class="mt-4 flex justify-end gap-4">
              <button
                  class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                Save Changes
              </button>
              <button
                  class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
              >
                Close
              </button>
          </div>
          </div>




          <!-- Preferences Tab -->
          <div v-show="activeTab === 3">
            <h2 class="text-xl font-bold mb-4">Preferences</h2>
            <p class="text-gray-600">
              Customize your preferences like theme, notifications, and language settings.
            </p>
            <div class="mt-4">
              <!-- Notifications Checkboxes -->
              <div class="mb-4">
                <label class="block text-lg font-semibold text-gray-700">Notifications:</label>
                <div class="flex items-center mb-2">
                  <ToggleSwitchComponent v-model="user.notifications.email" />
                  <label for="email" class="ml-2 text-gray-600">Email</label>
                </div>
                <div class="flex items-center">
                  <ToggleSwitchComponent v-model="user.notifications.sms" />
                  <label for="sms" class="ml-2 text-gray-600">SMS</label>
                </div>
              </div>

              <!-- Theme Selector -->
              <div class="mb-4">
                <label for="theme" class="block text-lg font-semibold text-gray-700">
                  Theme:
                </label>
                <select
                    v-model="user.preferences.theme"
                    id="theme"
                    class="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                </select>
              </div>

              <!-- Language Selector -->
              <div class="mb-4">
                <label for="language" class="block text-lg font-semibold text-gray-700">
                  Language:
                </label>
                <select
                    v-model="user.preferences.language"
                    id="language"
                    class="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                </select>
              </div>

              <!-- Save Button -->
              <div class="flex justify-end gap-4">
                <button
                    @click="savePreferences"
                    class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Save Changes
                </button>
                <button
                    class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";
import ToggleSwitchComponent from "@/components/Fields/ToggleSwitchComponent.vue";
export default {
  components: {ToggleSwitchComponent, DashboardLayout },
  data() {
    return {
      editMode: false,
      activeTab: 0,
      tabs: ["Profile", "Account", "Payment", "Preferences"],
      user: {
        avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s",
        name: "John Doe",
        email: "john.doe@example.com",
        phone: "123-456-7890",
        group: 'Admin',
        notifications: {
          email: true,
          sms: false,
        },
        preferences: {
          theme: "light",
          language:"en",
        }
      },

    };
  },
  methods: {
    saveProfile() {

      this.editMode = false;

      console.log("Profile saved:", this.user);
    },
    changeAvatar() {
      console.log("Change avatar clicked");
      // Logic for changing the avatar
    },
  },
};
</script>
