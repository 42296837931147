<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Upload Media</h2>
        <form @submit.prevent="sendUploadMedia">
          <div class="mb-2 flex gap-4">
            <input
                type="file"
                multiple
                @change="handleFileChange"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <p class="text-sm text-gray-400 mb-4">Max file size: 2MB. Accepted formats: JPG, PNG, WEBP.</p>
          <div class="mb-4 flex gap-4">
            <select
                v-model="brand"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option disabled value=""> Select Brand</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="mb-4">
            <textarea
                v-model="description"
                placeholder="Add a description..."
                rows="4"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            ></textarea>
          </div>
          <div class="flex justify-center mt-6">
            <ButtonComponent type="submit"  class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800">
              Upload
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: {DashboardLayout, ButtonComponent },
  data() {
    return {
      files: [],
      description: '',
      brand: '',
      brands: [],
    };
  },
  methods: {
    async sendUploadMedia(){
      const payload = {
        brand: this.brand,
        description: this.description,
      };
      // get the uploaded image and convert to base64
      const file = this.files[0];

      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      // append the image to the payload
      if(file) payload.image = base64Image;
      else{
        this.$toast.error('Please select an image to upload');
        return;
      }
      console.log("Base64 Image:", base64Image);

      let response = await this.$axios.request({
        url: '/media_library/upload',
        method: 'post',
        data: payload,
      });
      this.$toast.success('Media Uploaded Successfully')
      console.log(response);
    },
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
          url: '/brands/id',
          method: 'GET'
        });
        this.brands = response.data;
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    handleFileChange(event) {
      const acceptedFiles = ['image/jpeg', 'image/png', "image/jpeg", 'image/webp'];
      const maxSize = 2 * 1024 * 1024;

      this.files = Array.from(event.target.files).filter((file) => {
        if (!acceptedFiles.includes(file.type)) {
          this.$toast.error(`File format not supported: ${file.name}`);
          return false;
        }
        if (file.size > maxSize) {
          this.$toast.error(`File size exceeds 2MB: ${file.name}`);
          return false;
        }
        return true;
      });


    },
    handleUpload() {
      console.log("Uploading files:", this.files);
      console.log("Brand:", this.brand);
      console.log("Description:", this.description);
      this.files = [];
      this.description = '';
      this.brand = '';
    },
  },
  mounted() {
    this.fetchBrands();
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>
