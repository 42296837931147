<template>
  <div class="flex justify-center items-center bg-gray-100 h-screen">
    <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
      <h2 class="text-4xl text-orange-500 font-bold mb-4 text-center">404</h2>
      <p class="text-center text-lg text-black mb-6">Oops! The page you're looking for doesn't exist.</p>
      <div class="flex justify-center">
        <ButtonComponent
            @click="goHome"
            class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
        >
          Go to Home
        </ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";

export default {
  components: { ButtonComponent },
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>



<style scoped>
.container {
  max-width: 40%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>