<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Add a New Brand</h2>
        <form @submit.prevent="createNewBrand">
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandName"
                placeholder="Brand Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandDescription"
                placeholder="Brand Description"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandUrl"
                placeholder="Brand URL"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="flex justify-center mt-6">
            <button
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              Add Brand
            </button>
          </div>
        </form>
      </div>
      <!-- Error dialog -->
      <ModalDialogComponent
          :title="modalTitle"
          :message="modalMessage"
          :visible="showModal"
          @close="showModal = false"
      />
    </div>
  </DashboardLayout>
</template>
<script>

import {defineComponent} from "vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
export default defineComponent({
  components: {DashboardLayout, ModalDialogComponent},
  data() {
    return {
      showModal: false,
      modalTitle: "",
      modalMessage: "",
      brand: {
        brandName: "",
        brandDescription: "",
        brandUrl: "",
      },
    };
  },
  methods:{
    async createNewBrand() {
        try {
          console.log(this.brand);
          const response = await this.$axios.request(
              {
                url: "/brands/id",
                method: "POST",
                data: this.brand,
              }
          );
          console.log("Brand added successfully:", response.data);

          this.brand.brandName = '';
          this.brand.brandDescription = '';
          this.brand.brandUrl = '';
          if (response.data.status === 'success') {
            this.$toast.success('Brand added successfully!');
          } else {
            this.$toast.error('Error adding brand!');
          }
        } catch (error) {
          console.error("Error adding brand:", error);
          this.$toast.error("Error adding brand!");
          // this.showModal = true;
          // this.modalTitle = "Error";
          // this.modalMessage = "Error adding brand!";
        }
      }
    }
})
</script>

<style scoped>
</style>
