<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white mt-6">
      <div class="container max-w-4xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 text-center font-bold mb-12">User Groups</h2>

        <!-- Button to Add New User Group -->
        <div class="mb-4 text-right">
          <button
              @click="openModal"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
          >
            Add New User Group
          </button>
        </div>

        <!-- User Groups List -->
        <div class="flex flex-col">
          <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="flex-1 font-semibold">User Group</div>
            <div class="flex-1 font-semibold">Permissions</div>
            <div class="flex-1 font-semibold text-center">Action</div>
          </div>
          <div class="flex flex-col">
            <div
                v-for="group in userGroups"
                :key="group.id"
                class="flex justify-between items-center p-3 border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200"
            >
              <div class="flex-1">{{ group.name }}</div>
              <div class="flex-1">{{ group.permissions }}</div>
              <div class="flex-1 gap-4 flex justify-center items-center">
                <button
                    @click="editGroup(group.id)"
                    class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4 transition duration-200"
                >
                  <span class="material-icons">edit</span>
                </button>
                <button
                    @click="confirmToDelete(group.id)"
                    class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4 transition duration-200"
                >
                  <span class="material-icons">delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal for Confirmation Before Deletion -->
        <transition name="fade">
          <div v-if="isConfirmationModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
              <h3 class="text-2xl font-semibold text-gray-800 mb-4">Confirm {{ confirmationModalTitle }}</h3>
              <p class="text-gray-700 mb-4">Are you sure you want to delete this user group? This action cannot be undone.</p>

              <div class="flex justify-between items-center gap-4">
                <button
                    @click="deleteGroup"
                    class="bg-admin-200 text-white px-6 py-2 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 transition duration-200"
                >
                  {{ confirmationModalAction }}
                </button>
                <button
                    @click="closeDeleteModal"
                    class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
              <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ isEditMode ? 'Edit User Group' : 'Add New User Group' }}</h3>

              <!-- User Group Name Input -->
              <div class="mb-6">
                <label for="group-name" class="block text-sm font-medium text-gray-700 mb-2">User Group Name:</label>
                <input id="group-name" v-model="newGroupName" type="text" class="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-orange-500" placeholder="Enter group name" />
              </div>

              <!-- User Group Description -->
              <div class="mb-6">
                <label for="group-description" class="block text-sm font-medium text-gray-700 mb-2">User Group Description:</label>
                <input id="group-description" v-model="newGroupDescription" type="text" class="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-orange-500" placeholder="Enter group description" />
              </div>

              <!-- Permissions List -->
              <div class="mb-6">
                <label class="block text-sm font-medium text-gray-700 mb-2">Permissions:</label>
                <div class="mb-6">
                  <input type="checkbox" @change="toggleSelectAllPermissions" :checked="allPermissionsSelected" class="ml-2 rounded focus:ring-2 focus:ring-orange-500" /> Select/Unselect All
                </div>
                <div class="overflow-y-auto max-h-48 border border-gray-300 p-4 rounded-lg bg-gray-50">
                  <div class="flex flex-col space-y-3">
                    <label v-for="permission in allPermissions" :key="permission.id" class="flex items-center text-sm text-gray-700">
                      <input type="checkbox" v-model="newGroupPermissions" :value="permission.id" class="mr-3 rounded focus:ring-2 focus:ring-orange-500" />
                      {{ permission.name }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- Action Buttons -->
              <div class="flex justify-between items-center gap-4">
                <button
                    @click="isEditMode ? updateUserGroup() : addUserGroup()"
                    class="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200"
                >
                  {{ isEditMode ? 'Update User Group' : 'Create User Group' }}
                </button>
                <button
                    @click="closeModal"
                    class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </transition>

      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: {DashboardLayout, ButtonComponent },
  data() {
    return {
      isModalOpen: false,
      isConfirmationModalOpen: false,
      confirmationModalAction: '',
      confirmationModalTitle: '',
      groupIdToDelete: null,
      newGroupName: '',
      newGroupDescription: '',
      newGroupPermissions: [],
      allPermissionsSelected: false,
      userGroups: [],
      permissionsById: {},
      allPermissions: [],
      group_to_permission: [],
      isEditMode: false,
      editingGroupId: null
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
      this.checkAllPermissionsSelected();
    },
    updateUserGroup() {
        const updatedGroup = {
            name: this.newGroupName,
            permission_ids: this.newGroupPermissions,
            description: this.newGroupDescription,
        };
        this.$axios.request({
            url: `/usergroup/index/${this.editingGroupId}`,
            method: 'PUT',
            data: updatedGroup
        })
        .then(response => {
            const groupIndex = this.userGroups.findIndex(group => group.id === this.editingGroupId);
            if (groupIndex !== -1) {
                this.userGroups[groupIndex] = {
                    name: updatedGroup.data.name,
                    permissions: updatedGroup.data.permissions,
                };
            }
            this.closeModal();
            this.$toast.success('User group updated successfully!');
        })
        .catch(error => {
            console.error('Error updating user group:', error);
            this.$toast.error('Failed to update user group. Please try again.');
        });
    },
    closeModal() {
      this.isModalOpen = false;
      this.newGroupName = '';
      this.newGroupPermissions = [];
      this.checkAllPermissionsSelected();
    },
    toggleSelectAllPermissions(event) {
      if (event.target.checked) {
        this.newGroupPermissions = this.allPermissions.map(permission => permission.id);
      } else {
        this.newGroupPermissions = [];
      }
    },
    checkAllPermissionsSelected() {
      this.allPermissionsSelected = this.newGroupPermissions.length === this.allPermissions.length;
    },
    addUserGroup() {
      if (this.newGroupName && this.newGroupPermissions.length) {
        const newGroup = {
          name: this.newGroupName,
          permission_ids: this.newGroupPermissions,
          description: this.newGroupDescription,
        };
        console.log(newGroup);

        this.$axios.request({
          method: 'POST',
          url: '/usergroup',
          data: newGroup,
        })
            .then(response => {
              this.userGroups.push({
                id: response.data.id,
                name: response.data.name,
                permissions: response.data.permissions,
              });
              this.closeModal();
              this.$toast.success('User group saved successfully!');
            })
            .catch(error => {
              console.error('Error saving user group:', error);
              this.$toast.error('Failed to save user group. Please try again.');
            });
      } else {
        alert("Please enter a group name and select permissions.");
      }
    },
    fetchAllUserGroups() {
      this.$axios.request({
        method: 'GET',
        url: '/usergroup',
      })
          .then(response => {
            this.userGroups = response.data.user_groups.map(group => ({
              id: group.id,
              name: group.name,
              description: group.description,
              permissions: (response.data.group_to_permission[group.id] || [])
                  .map(permissionId => this.permissionsById[permissionId] || 'Unknown Permission') // Fallback text
                  .filter(permission => permission) // Remove any undefined or empty permissions
                  .join(', '),
              permission_ids: response.data.group_to_permission[group.id] || [],
            }));
          })
          .catch(error => {
            console.error('Error fetching user groups:', error);
          });
    },
    editGroup(id) {
      console.log('1aa', this.userGroups);
      const group = this.userGroups.find(group => group.id === id);
      console.log('Finding group...', group);
      this.newGroupName = group.name;
      this.newGroupDescription = group.description;
      this.newGroupPermissions = group.permission_ids;
      this.editingGroupId = id;
      this.isEditMode = true;
      this.openModal();
    },
    deleteGroup() {
      this.deleteUserGroup(this.groupIdToDelete);
      this.closeDeleteModal();
    },
    async fetchPermissions() {
      try {
        const response = await this.$axios.get('/permissions');
        this.allPermissions = response.data.map(permission => ({
          id: permission.id,
          name: permission.name,
        }));
        this.permissionsById = response.data.reduce((acc, permission) => {
          acc[permission.id] = permission.name;
          return acc;
        }, {});

      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    },
    async deleteUserGroup(id) {
      try {
        await this.$axios.delete(`/usergroup/${id}`);
        this.userGroups = this.userGroups.filter(group => group.id !== id);
      } catch (error) {
        console.error('Error deleting user group:', error);
        alert('Failed to delete user group. Please try again.');
      }
    },
    confirmToDelete(id) {
      this.groupIdToDelete = id;
      this.confirmationModalAction = 'Delete User Group';
      this.confirmationModalTitle = 'Delete User Group';
      this.isConfirmationModalOpen = true;
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.groupIdToDelete = null;
    },
  },
  mounted() {
    this.fetchPermissions().then(() => {
      this.fetchAllUserGroups();
    });
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

</style>