import { createApp } from 'vue';
import App from './App.vue';
import { useToast } from "vue-toastification";
import './main.css';
import 'material-design-icons/iconfont/material-icons.css';
import router from "@/module/router";
import axios from './module/axios';

import Dialog from 'vue3-dialog';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import Toast CSS


// Create the Vue application instance
const app = createApp(App);

// Use vue3-dialog and pass the dialog options
app.use(Dialog);

// Set global properties for Axios and Toast
app.config.globalProperties.$set = (obj, key, value) => {
        obj[key] = value;
        return obj;
};
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$toast = useToast({
        position: "bottom-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
});


// Use the router in the app
app.use(router);

// Mount the application to the DOM
app.mount('#app');
