<template>
  <div class="sticky bottom-0 p-4 border-t border-gray-700 flex items-center justify-between">
    <!-- User Info -->
    <div class="flex items-center">
      <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s"
          alt="User Avatar"
          class="w-10 h-10 rounded-full mr-3"
      />
      <div v-if="!isCollapsed">
        <span class="text-sm font-medium text-orange-500">{{ user.firstName }} {{ user.lastName }}</span>
        <br />
        <span class="text-xs text-white">Last login: {{ user.lastLogin }}</span>
      </div>
    </div>

    <!-- Logout Button -->
    <button
        @click="logout"
        class="ml-4 pb-0.5 material-icons text-orange-500"
    >
      power_settings_new
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        lastLogin: "2021-01-01 12:00:00"
      }
    }
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    async getUser() {
      let response = await this.$axios.request({
        method: "GET",
        url: "/user"
      });

      let user = response.data.data;
      this.user.firstName = user.first_name;
      this.user.lastName = user.last_name;
      this.user.lastLogin = user.last_login;
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
.material-icons {
  font-family: 'Material Icons', serif;
  font-size: 24px;
}
</style>