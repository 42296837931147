import { createRouter, createWebHistory } from 'vue-router';
import axios from '#src/module/axios.js'; // Import axios directly to use it

import Login from '../views/LoginPage.vue';
import Register from '../views/RegisterPage.vue';
import Dashboard from '../views/dashboard/DashboardOverviewPage.vue';
import Inbox from '../views/dashboard/InboxPage.vue';
import Message from '../views/dashboard/MessagePage.vue';
import Notification from '../views/dashboard/NotificationPage.vue';
import Users from '../views/users/UsersPage.vue';
import UsersCreate from "../views/users/UserCreatePage.vue";
import BrandsCreate from "../views/brands/CreateBrandsPage.vue";
import BrandsManage from "../views/brands/ManageBrandsPage.vue";
import UserProfile from '../views/users/UserProfilePage.vue';
import UsersEdit from "../views/users/UserEditPage.vue";
import UserGroups from "../views/users/UserGroupsPage.vue";
import UserPermissions from "../views/users/UserPermissions.vue";
import Affiliates from '../views/affiliates/AffiliatesPage.vue';
import PendingAffiliates from "../views/affiliates/PendingAffiliatesPage.vue";
import AffiliateStats  from "../views/affiliates/AffiliateStatsPage.vue";
import MediaLibrary from "../views/media/MediaLibraryPage.vue";
import ActiveBanners from "../views/media/ActiveBannersPage.vue";
import UploadMedia from "../views/media/UploadMediaPage.vue";
import BannerGenerator from "../views/media/BannerGeneratorPage.vue";

import ManageCommissions from "../views/commission/ManageCommissionsPage.vue";
import CommissionsCreate from "../views/commission/CommissionCreate.vue";
import ManageContracts from "../views/commission/ManageContractsPage.vue";
import ContractCreate from "../views/commission/ContractCreate.vue";

import AddSource from "../views/sources/SourceCreatePage.vue";
import ManageSources from "../views/sources/ManageSourcesPage.vue";

import AddProduct from "../views/products/ProductCreatePage.vue";
import ManageProducts from "../views/products/ManageProductsPage.vue";

import WhitelabelSettings from "../views/settings/WhitelabelSettingsPage.vue";

import PayoutHistory from "../views/payouts/PayoutHistoryPage.vue";
import ViewPayout from "../views/payouts/ViewPayoutPage.vue";

import NotFoundPage from "../views/404Page.vue";
import model from "@/module/model";
import instance from "#src/module/axios.js";


// Define the routes for the application
const routes = [
  // { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/', component: Login },
  { path: '/register', component: Register },
  { path: '/dashboard/overview', component: Dashboard },
  { path: '/dashboard/profile', component: UserProfile },
  { path: '/dashboard/inbox', component: Inbox },
  // { path: '/dashboard/message/:id', component: Message },
  { path: '/dashboard/notifications', component: Notification },
  //{ path: '/dashboard/users', component: Users, beforeEnter: checkAuthStatus },
  { path: '/dashboard/users', component: Users },
  //{ name: 'UserEdit', path: '/dashboard/users/:id', component: UsersEdit, props:true },
  { path: '/dashboard/users/create', component: UsersCreate },
  { path: '/dashboard/users/groups', component: UserGroups },
  { path: '/dashboard/brands/create', component: BrandsCreate },
  { path: '/dashboard/brands/manage', component: BrandsManage },
  { path: '/dashboard/users/permissions', component: UserPermissions },
  { path: '/dashboard/affiliates/manage', component: Affiliates },
  { path: '/dashboard/affiliates/pending', component: PendingAffiliates },
  { path: '/dashboard/affiliates/stats', component: AffiliateStats},
  { path: '/dashboard/media/upload', component: UploadMedia },
  { path: '/dashboard/media/library', component: MediaLibrary },
  { path: '/dashboard/media/banners', component: BannerGenerator },
  { path: '/dashboard/commission/manage', component: ManageCommissions },
  { path: '/dashboard/commission/create', component: CommissionsCreate },
  { path: '/dashboard/commission/contracts/create', component: ContractCreate },
  { path: '/dashboard/commission/contracts/manage', component: ManageContracts },
  { path: '/dashboard/sources/manage', component: ManageSources },
  { path: '/dashboard/sources/add', component: AddSource },
  { path: '/dashboard/products/create', component: AddProduct },
  { path: '/dashboard/products/manage' , component: ManageProducts },
  { path: '/dashboard/settings/whitelabel', component: WhitelabelSettings },
  { path: '/dashboard/payouts/history', component: PayoutHistory },
  { path: '/dashboard/payouts/view', component: ViewPayout },
  { path: '/dashboard/media/active', component: ActiveBanners },

  { path: '/redirect/:tracking_id',
    redirect: (to) => { const trackingId = to.params.tracking_id;
      //return `https://affiliateapi.web24.me/redirect/index/${trackingId}`;
      window.location.href = `https://affiliateapi.web24.me/redirect/index/${trackingId}`;
    }},
  { path: "/:catchAll(.*)", component: NotFoundPage }
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});


async function isAuthenticated() {
  const token = localStorage.getItem('token') || '';

    if (token === '') {
        return false;
    }
    try{
      let response = await axios.request({
        url: '/auth',
        method: 'POST',
      });

      if(response.data.status === 'success'){
          return true;
      }

    }catch(error){
      console.log(error);

    }

}

// Before each route navigation
router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem('token') || null;
  let isValidAuth = await isAuthenticated() || null ;
  console.log(from.path, to.path);

  if (!isValidAuth) {
    localStorage.removeItem('token');
    axios.defaults.headers.Authorization = null;
    token = null;
  }else{
    if(from.path === '/' && to.path === '/' || from.path === '/' && to.path === '/login'){
        window.location.href = '/dashboard/overview';
    }
  }

  if (token === null && to.path !== '/login' && to.path !== '/register') {
    next('/login');
    return;
  }
  next();
});


// After each route navigation
router.afterEach(async (to, from) => {
  //const isAuth = await isAuthenticated();
  //reload the page








});


export default router;

