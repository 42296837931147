<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-6xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Manage Affiliates</h2>

        <!-- Affiliates List -->
        <div class="flex flex-col">
          <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="flex-1 font-semibold">Affiliate Name</div>
            <div class="flex-1 font-semibold">Affiliate Email</div>
            <div class="flex-1 font-semibold">Client</div>
            <div class="flex-1 font-semibold">Brand</div>
            <div class="flex-1 font-semibold">*Status*</div>
            <div class="flex-1 font-semibold">Actions</div>
          </div>
          <div class="flex flex-col">
            <div
                v-for="affiliate in assignedAffiliates"
                :key="affiliate.id"
                class="flex justify-between items-center p-3 border-b border-gray-300 hover:bg-gray-200"
            >
              <div class="flex-1">{{ affiliate.name }}</div>
              <div class="flex-1">{{ affiliate.email }}</div>
              <div class="flex-1">{{ affiliate.client }}</div>
              <div class="flex-1">{{ affiliate.brand }}</div>
              <div class="flex-1">
                <span :class="affiliate.active ? 'text-green-500' : 'text-red-500'">
                  {{ affiliate.active ? 'Active' : 'Inactive' }}
                </span>
              </div>
              <div class="flex-1 flex justify-center">
                <ButtonComponent @click="openEditModal(affiliate)" class="bg-orange-500 text-white px-2 py-1 rounded hover:bg-orange-600 mx-2">
                  <span class="material-icons">edit</span>
                </ButtonComponent>
                <ButtonComponent @click="removeAffiliate(affiliate.id)" class="bg-orange-500 text-white px-2 py-1 rounded hover:bg-orange-600 mx-2">
                  <span class="material-icons">delete</span>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Affiliate Form -->
        <h3 class="text-xl text-orange-500 font-semibold mt-6">Assign New Affiliate to Client/Brand</h3>
        <form @submit.prevent="handleAddAffiliate" class="mt-4 flex flex-col">
          <div class="flex flex-wrap gap-4 mb-4">
            <!-- Select Client and Select Brand in two columns -->
            <div class="flex-1 min-w-[calc(50%-0.5rem)]">
              <select v-model="newAffiliate.client" @change="handleClientChange" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
                <option value="" disabled>Select Client</option>
                <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.email }}</option>
              </select>
            </div>
            <div class="flex-1 min-w-[calc(50%-0.5rem)]">
              <select v-model="newAffiliate.brand" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
                <option value="" disabled>Select Brand</option>
                <option v-for="brand in filteredBrands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
              </select>
            </div>
          </div>

          <!-- Select Affiliate (full width) -->
          <div class="mb-4">
            <select v-model="newAffiliate.affiliate" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
              <option value="" disabled>Select Affiliate</option>
              <option v-for="affiliate in affiliates" :key="affiliate.id" :value="affiliate.id">{{ affiliate.name }}</option>
            </select>
          </div>

          <div class="flex justify-center mt-4">
            <ButtonComponent type="submit" class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800">
              Add Affiliate
            </ButtonComponent>
          </div>
        </form>

        <!-- Edit Affiliate Modal -->
        <div v-if="showEditModal" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div class="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 class="text-xl text-orange-500 font-semibold mb-4">Edit Affiliate</h3>
            <form @submit.prevent="updateAffiliate">
              <div class="mb-4">
                <label for="editAffiliateName" class="block font-semibold">Name</label>
                <input v-model="editingAffiliate.name" type="text" id="editAffiliateName" class="p-3 w-full border border-gray-300 rounded" />
              </div>
              <div class="mb-4">
                <label for="editAffiliateEmail" class="block font-semibold">Email</label>
                <input v-model="editingAffiliate.email" type="email" id="editAffiliateEmail" class="p-3 w-full border border-gray-300 rounded" />
              </div>
              <div class="mb-4">
                <label for="editAffiliateBrand" class="block font-semibold">Brand</label>
                <select v-model="editingAffiliate.brand_id" id="editAffiliateBrand" class="p-3 w-full border border-gray-300 rounded">
                  <option value="" disabled>Select Brand</option>
                  <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
                </select>
              </div>
              <div class="flex justify-between gap-4">
                <ButtonComponent type="submit" class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800">Update</ButtonComponent>
                <ButtonComponent @click="closeEditModal" class="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400">Cancel</ButtonComponent>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      // ],
      assignedAffiliates: [],
      affiliates: [],
      clients: [],
      brands: [],
      filteredBrands: [],
      newAffiliate: {
        client: "",
        brand: "",
        affiliate: ""
      },
      showEditModal: false,
      editingAffiliate: {
        id: null,
        name: "",
        email: "",
        brandId: ""
      }
    };
  },
  methods: {
    getBrandName(brandId) {
      const brand = this.brands.find(b => b.id === brandId);
      return brand ? brand.name : "Unknown";
    },
    toggleStatus(id) {
      const affiliate = this.affiliates.find(a => a.id === id);
      if (affiliate) {
        affiliate.active = !affiliate.active;
      }
    },
    async fetchAffiliates() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/affiliates/clients'
        });

        const aff_response = await this.$axios.request({
          method: 'get',
          url: '/affiliates/manage'
        });




        console.log(aff_response);

        if(aff_response.status === 200)
        {
          this.affiliates = aff_response.data.users.map(aff => ({
            id: aff.id,
            name: `${aff.first_name} ${aff.last_name}` // Correctly combine first and last name
          }));
        }


        if (response.status === 200 && response.data) {
          this.assignedAffiliates = response.data.results.map(affiliate => ({
            name: affiliate.affiliate_name,
            email: affiliate.affiliate_email,
            client: affiliate.client,
            brand: affiliate.brand,
            brand_id: affiliate.brand_id,
            active: affiliate.status
          }));

          this.brands = response.data.brands;

        }


        console.log('Assigned Affiliates:', this.assignedAffiliates);
      } catch (error) {
        console.error('Error fetching affiliates:', error);
      }
    },
    async fetchClients(){
      try{
        const response = await this.$axios.get('/clients');
        if(response.status === 200){
          this.clients = response.data.map(client => ({
            id: client.id,
            email: client.email
          }));
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    removeAffiliate(id) {
      this.affiliates = this.affiliates.filter(a => a.id !== id);
    },
    async handleClientChange() {
      if (this.newAffiliate.client) {
        try {
          const response = await this.$axios.get(`/brands?user_id=${this.newAffiliate.client}`);
          if (response.status === 200 && response.data) {
            this.filteredBrands = response.data;
          }

          console.log('Filtered Brands:', this.filteredBrands);
        } catch (error) {
          console.error('Error fetching brands:', error);
        }
      }
    },
    async sendNewClientToAffiliate(payload) {
      try {
        const response = await this.$axios.request({
          method: 'POST',
          url: '/affiliates/clients',
          data: payload,
        });
        if (response.status === 200 && response.data) {
          // Handle the response if necessary, e.g., update UI or show a success message
          this.$toast.success('Client Assigned to Affiliated Successfully!');
          return response.data;  // You can return the response data if you want to use it later
        }
      } catch (error) {
        console.error('Error sending new client to affiliate:', error);
        // Optionally, handle the error (e.g., show an error message)
        return null;  // Return null or some indication of failure
      }
    },
    async handleAddAffiliate() {
      const payload = {
        client_id: this.newAffiliate.client,
        brand_id: this.newAffiliate.brand,
        affiliate_id: this.newAffiliate.affiliate
      };
      const response = await this.sendNewClientToAffiliate(payload);

      if (response) {

        // Update the affiliates list if the request was successful
        const newId = this.affiliates.length ? Math.max(this.affiliates.map(a => a.id)) + 1 : 1;
        this.affiliates.push({
          id: newId,
          name: this.newAffiliate.affiliate.name,
          email: this.newAffiliate.affiliate.email,
          brandId: this.newAffiliate.brand,
          clientId: this.newAffiliate.client,
          active: true
        });
        this.newAffiliate = { client: "", brand: "", affiliate: "" };
      }
    },
    openEditModal(affiliate) {
      this.editingAffiliate = { ...affiliate };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editingAffiliate = null;
    },
    updateAffiliate() {
      const index = this.affiliates.findIndex(a => a.id === this.editingAffiliate.id);
      if (index !== -1) {
        this.affiliates[index] = { ...this.editingAffiliate };
      }
      this.closeEditModal();
    }
  },
  mounted() {
    this.fetchAffiliates().then(() => {
      this.fetchClients();
    });
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}

input:focus {
  border-color: #f97316; /* Tailwind's orange-500 */
}

select:focus {
  border-color: #f97316; /* Tailwind's orange-500 */
}
</style>
