<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold text-orange-500">Manage Contracts</h2>
            </div>
            <div class="mt-12">
              <div class="flex flex-col bg-gray-200 rounded-lg overflow-hidden">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">Contract Name</div>
                  <div class="py-3 px-4 font-semibold flex-1">Affiliate</div>
                  <div class="py-3 px-4 font-semibold flex-1">Product</div>
                  <div class="py-3 px-4 font-semibold flex-none">Actions</div>
                </div>
                <div v-for="contract in contracts" :key="contract.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ contract.name }}</div>
                  <div class="py-3 px-4 flex-1">{{ contract.affiliate }}</div>
                  <div class="py-3 px-4 flex-1">{{ contract.product }}</div>
                  <div class="py-3 px-4 flex-none">
                    <div class="flex justify-center max-w-xs">
                      <ButtonComponent @click="editContract(contract)" class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2">
                        <span class="material-icons">visibility</span>
                      </ButtonComponent>
                      <ButtonComponent  class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2">
                        <span @click="deleteContract(contract.id)" class="material-icons">delete</span>
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for Adding or Editing a Contract -->
      <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="fixed inset-0 bg-black opacity-50" @click="closeModal"></div>
        <div class="bg-white rounded-lg shadow-lg z-10 p-6 max-w-md w-full">
          <h2 class="text-2xl font-bold text-orange-500 mb-4">View Contract</h2>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <input
                  type="text"
                  v-model="newContract.name"
                  placeholder="Contract Name"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  disabled
              />
            </div>
            <div class="mb-4">
              <input
                  type="text"
                  v-model="newContract.affiliate"
                  placeholder="Affiliate"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  disabled
              />
            </div>
            <div class="mb-4">
              <input
                  type="text"
                  v-model="newContract.product"
                  placeholder="Product"
                  class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  disabled
              />
            </div>
            <div class="flex justify-center mt-6">
<!--              <ButtonComponent-->
<!--                  type="submit"-->
<!--                  class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"-->
<!--              >-->
<!--                {{ isEditMode ? 'Update Contract' : 'Add Contract' }}-->
<!--              </ButtonComponent>-->
            </div>
          </form>
          <ButtonComponent @click="closeModal" class="bg-orange-600 text-white px-4 py-2 rounded mt-4 hover:bg-orange-800">
            Close
          </ButtonComponent>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      contracts: [],
      products: [],
      affiliates: [],
      isModalOpen: false,
      isEditMode: false,
      newContract: {
        id: null,
        name: '',
        affiliate: '',
        product: '',
      },
    };
  },
  methods: {
    editContract(contract) {
      this.isModalOpen = true;
      this.isEditMode = true;
      this.newContract = {...contract};
    },
    async deleteContract(contractId) {
      let response = await this.$axios.request({
        url: '/contracts/index/' + contractId,
        method: 'DELETE'
      });

      if(response.status === 200) this.$toast.success('Contract Deleted Successfully!');
      else this.$toast.fail('Failed to delete contract');


      this.contracts = this.contracts.filter(contract => contract.id !== contractId);
    },
    openModal() {
      this.isModalOpen = true;
      this.isEditMode = false;
      this.newContract = {id: null, name: '', affiliate: '', product: ''};
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    handleSubmit() {
      if (this.isEditMode) {
        this.updateContract();
      } else {
        this.addContract();
      }
      this.closeModal();
    },
    addContract() {
      this.contracts.push({
        id: this.contracts.length + 1,
        name: this.newContract.name,
        affiliate: {name: this.newContract.affiliate},
        product: {name: this.newContract.product},
      });
    },
    async updateContract() {
      let response = await this.$axios.request({
          url: `/contracts/index/${this.newContract.id}`,
          method: 'PUT',
          data: this.newContract
      });

      if(response.status === 200){
        this.$toast.success('Contract updated successfully');


        const index = this.contracts.findIndex(contract => contract.id === this.newContract.id);
        if (index !== -1) {
          this.contracts[index] = {...this.newContract};
        }
        return;
      }
      this.$toast.fail('Failed to update contract');

    },
    async fetchContracts(){
      let response = await this.$axios.request({
        url: '/contracts',
        method: 'GET'
      });


      console.log('fetching contracts...', response);
      this.products = response.data.products;
      this.affiliates = response.data.affiliates;


      this.contracts = response.data.contracts.map(
        contract => ({
          id: contract.id,
          name: contract.contract_name,
          affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
          product: this.products[contract.product_id].name
        })
      );
      console.log(this.contracts);
    },
    resetForm() {
      this.newContract = {id: null, name: '', affiliate: '', product: ''};
    },
  },
  mounted() {
    this.fetchContracts();
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}
</style>
