import axios from 'axios';


const instance =  axios.create({
    //withCredentials: true,
    baseURL: 'https://affiliateapi.web24.me',
    timeout: 2000,
    headers: {
        'Content-Type': "application/json",
    },
});

const token = localStorage.getItem('token') || null ;
if (token)
    instance.defaults.headers.Authorization = `Bearer ${token}`;


export default instance;

