<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen mt-6">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold text-orange-500">Manage Brands</h2>
            </div>
            <div v-if="loading" class="mt-12 text-center text-orange-500">
              Loading brands...
            </div>
            <div v-else class="mt-12">
              <div class="bg-gray-200 rounded-lg">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">Name</div>
                  <div class="py-3 px-4 font-semibold flex-1">Client</div>
                  <div class="py-3 px-4 font-semibold flex-1">URL</div>
                  <div class="py-3 px-4 font-semibold flex-none">Actions</div>
                </div>
                <div v-for="brand in brands" :key="brand.id" class="flex border-b border-gray-400 hover:bg-gray-300">
                  <div class="py-3 px-4 flex-1">{{ brand.name }}</div>
                  <div class="py-3 px-4 flex-1">{{ brand.user_id || "N/A" }}</div>
                  <div class="py-3 px-4 flex-1">{{ brand.url }}</div>
                  <div class="py-3 px-4 flex-none">
                    <button @click="editBrand(brand)" class="mx-2 bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600">
                      Edit
                    </button>
                    <button @click="confirmDelete(brand.id)" class="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
                      Delete
                    </button>
                  </div>
                </div>
                <div v-if="Object.keys(brands).length < 1" class="p-4 text-center text-gray-500">
                  No brands found.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <transition name="fade">
        <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ isEditMode ? 'Edit Brand' : 'Add New Brand' }}</h3>
            <div class="mb-6">
              <label for="brand-name" class="block text-sm font-medium text-gray-700">Brand Name:</label>
              <input
                  id="brand-name"
                  v-model="newBrand.name"
                  type="text"
                  class="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter brand name"
              />
              <span v-if="errors.name" class="text-red-500 text-sm">{{ errors.name }}</span>
            </div>
            <div class="mb-6">
              <label for="brand-url" class="block text-sm font-medium text-gray-700">Brand URL:</label>
              <input
                  id="brand-url"
                  v-model="newBrand.url"
                  type="url"
                  class="border border-gray-300 rounded-lg p-3 w-full focus:ring-2 focus:ring-orange-500"
                  placeholder="Enter brand URL"
              />
              <span v-if="errors.url" class="text-red-500 text-sm">{{ errors.url }}</span>
            </div>
            <div class="flex justify-between">
              <button
                  @click="handleSubmit"
                  :disabled="!newBrand.name || !newBrand.url"
                  class="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600"
              >
                {{ isEditMode ? 'Update Brand' : 'Add Brand' }}
              </button>
              <button @click="closeModal" class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600">
                Close
              </button>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="isConfirmationModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">Confirm Deletion</h3>
            <p class="text-gray-700 mb-4">Are you sure you want to delete this brand? This action cannot be undone.</p>
            <div class="flex justify-between">
              <button @click="deleteBrand" class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600">
                Confirm
              </button>
              <button @click="closeDeleteModal" class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      loading: true,
      isModalOpen: false,
      isEditMode: false,
      isConfirmationModalOpen: false,
      errors: {},
      brands: [],
      newBrand: { id: null, name: "", url: "" },
      brandToDelete: null,
    };
  },
  methods: {
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/brands/id'
        });
        console.log(response);
        this.brands = response.data;
        console.log(response);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        this.loading = false;
      }
    },
    openModal() {
      this.isModalOpen = true;
      this.isEditMode = false;
      this.resetForm();
    },
    editBrand(brand) {
      this.newBrand = { ...brand };
      this.isEditMode = true;
      this.isModalOpen = true;
    },
    confirmDelete(brandId) {
      this.brandToDelete = brandId;
      this.isConfirmationModalOpen = true;
    },
    async deleteBrand() {
      console.log("Deleting brand:", this.brandToDelete);
      try {
        let response = await this.$axios.request({
          method: 'delete',
          url: `/brands/id/${this.brandToDelete}`
        });

       this.brands = response.data.brands;

       console.log('Since delete is successful, the new brands are:', this.brands);

        console.log(response);
      } catch (error) {
        console.error("Error deleting brand:", error);
      } finally {
        this.closeDeleteModal();
      }
    },
    async handleSubmit() {
      // this.errors = {};
      // if (!this.newBrand.name) this.errors.name = "Name is required.";
      // if (!this.newBrand.url) this.errors.url = "URL is required.";
      //
      // if (Object.keys(this.errors).length) return;

          const response = await this.$axios.request({
              url: `/brands/id/${this.newBrand.id}`,
              method: 'PUT',
              data: this.newBrand
          });
          console.log(typeof this.brands);
          console.log(this.brands);
          const index = this.brands.findIndex((b) => b.id === this.newBrand.id);
          if (index !== -1) this.brands[index] = response.data.brand;

          if(response.status === 200) {
            this.$toast.success('Brand edited successfully!');

          }else {
            return this.$toast.error('Error editing brand!');
          }
      },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.brandToDelete = null;
    },
    resetForm() {
      this.newBrand = { id: null, name: "", url: "" };
      this.errors = {};
    },
  },
  async created() {
    await this.fetchBrands();
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
}
</style>
