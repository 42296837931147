<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-4xl bg-white text-gray-800 p-8 shadow-xl rounded-xl">
        <h2 class="text-3xl text-orange-500 font-bold mb-8 text-center">Create Your Affiliate Banner</h2>

        <!-- Step Indicator -->
        <div class="step-indicator p-4 bg-gradient-to-r from-orange-500 via-yellow-400 to-orange-500 flex justify-between items-center mb-8 rounded-lg shadow-lg text-black">
          <span v-for="i in 5" :key="i" :class="stepClass(i)">
            Step {{ i }}: {{ stepNames[i - 1] }}
          </span>
        </div>

        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="step" class="step-content mb-4">
            <!-- Step 1: Select Product -->
            <div v-if="step === 1" class="step-box">
              <label for="product" class="block text-lg font-medium mb-3">Select Product</label>
              <select v-model="selectedProduct" @change="nextStep" class="step-select">
                <option disabled value="">Choose Product</option>
                <option v-for="product in products" :key="product.id" :value="product">{{ product.name }}</option>
              </select>
            </div>

            <!-- Step 2: Select Brand -->
            <div v-if="step === 2" class="step-box">
              <label for="brand" class="block text-lg font-medium mb-3">Select Brand</label>
              <select v-model="selectedBrand" @change="nextStep" class="step-select">
                <option disabled value="">Choose Brand</option>
                <option v-for="brand in brands" :key="brand.id" :value="brand">{{ brand.name }}</option>
              </select>
            </div>

            <!-- Step 3: Select Banner -->
            <div v-if="step === 3" class="step-box">
              <label for="banner" class="block text-lg font-medium mb-3">Select Banner</label>
              <select v-model="selectedBanner" @change="nextStep" class="step-select">
                <option disabled value="">Choose Banner</option>
                <option v-for="banner in banners" :key="banner.id" :value="banner">{{ banner.name }}</option>
              </select>
            </div>

            <!-- Step 4: Select Source -->
            <div v-if="step === 4" class="step-box">
              <label for="source" class="block text-lg font-medium mb-3">Select Source</label>
              <select v-model="selectedSource" @change="nextStep" class="step-select">
                <option disabled value="">Choose Source</option>
                <option v-for="source in sources" :key="source.id" :value="source">{{ source.name }}</option>
              </select>
            </div>

            <!-- Step 5: Generate & Preview -->
            <div v-if="step === 5" class="step-box">
              <label class="block text-lg font-medium mb-3">Banner Preview</label>
              <div class="flex justify-center mt-6">
                <ButtonComponent @click="generateBannerCode" class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700">
                  Generate Code
                </ButtonComponent>
              </div>

              <div v-if="generatedCode" class="mt-6">
                <h3 class="text-xl font-semibold text-orange-500 mb-3">Preview</h3>
                <div class="banner-preview bg-gray-100 p-4 rounded-lg shadow-md">
                  <img :src="'https://affiliateapi.web24.me/' + selectedBanner.imageUrl" alt="Banner Preview" class="w-full mb-4 rounded-lg shadow-lg" />
                  <h4 class="text-lg font-medium text-orange-600 mb-2">Generated HTML Code</h4>
                  <div class="code-box bg-gray-200 p-3 rounded-md text-black text-sm overflow-auto">
                    <pre ref="codeSnippet">{{ generatedCode }}</pre>
                  </div>
                  <button @click="copyCode" class="bg-orange-600 text-white px-5 py-2 rounded-lg mt-4 hover:bg-orange-700">
                    Copy HTML Code
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Navigation Buttons -->
        <div class="mt-8 flex justify-between items-center">
          <button v-if="step > 1" @click="previousStep" class="bg-gray-400 text-white px-6 py-3 rounded-lg hover:bg-gray-500">
            Back
          </button>
          <button v-if="step < 5" :disabled="!canProceed" @click="nextStep" class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700">
            Next
          </button>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      step: 1,
      products: [],
      brands: [],
      banners: [],
      sources: [],
      selectedProduct: "",
      selectedBrand: "",
      selectedBanner: "",
      selectedSource: "",
      generatedCode: "",
    };
  },
  computed: {
    filteredBanners() {
      return this.banners.filter(
          (banner) =>
              (!this.selectedProduct || banner.productId === this.selectedProduct.id) &&
              (!this.selectedBrand || banner.brandId === this.selectedBrand.id)
      );
    },
    canProceed() {
      return (
          (this.step === 1 && this.selectedProduct) ||
          (this.step === 2 && this.selectedBrand) ||
          (this.step === 3 && this.selectedBanner) ||
          (this.step === 4 && this.selectedSource)
      );
    },
    stepNames() {
      return ["Product", "Brand", "Banner", "Source", "Generate"];
    },
  },
  methods: {
    nextStep() {
      if (this.canProceed) {
        this.step++;
      }
    },
    previousStep() {
      this.step--;
    },
    async generateBannerCode() {
      let response = await this.$axios.request({
        method: "POST",
        url: "/banner_generator/index",
        data: {
          productId: this.selectedProduct.id,
          brandId: this.selectedBrand.id,
          bannerId: this.selectedBanner.id,
          sourceId: this.selectedSource.id,
        },
      });
      var trackingId = response.data.banner.tracking_id;

      let banner = await this.$axios.request({
        method: "GET",
        url: "/media_library/index/" + this.selectedBanner.id,
      });


      if(banner.data.id === this.selectedBanner.id)
        this.selectedBanner.imageUrl = banner.data.src_path;
      else
        console.log("Error fetching banner image");


      console.log(banner.data);




      let banner_id = response.data.banner.banner_id;

      // get the banner with banner id
      console.log('This is the banner id.. ', banner_id);

      response = await this.$axios.request({
        method: "GET",
        url: "/media_library/index/" + banner_id,
      });

      console.log('This is the fetched banner... ', response.data);

      this.bannerURL = 'http://affiliate.web24.me:8080/redirect/' + trackingId;
      this.imageUrl = 'https://affiliateapi.web24.me' + response.data.src_path;

      this.generatedCode = `
        <div style="display:inline-block;">
          <a href="${this.bannerURL}" target="_blank">
            <img src="${this.imageUrl}" alt="${this.selectedBanner.name}" />
          </a>
        </div>
        `;
    },
    async fetchProducts() {
      try {
        const response = await this.$axios.request({
          method: "GET",
          url: "/products",
        });

        console.log(response);

        // Assuming the response structure is { data: { products: [...], brands: { ... } } }
        // Map over the products array to set them properly
        this.products = response.data.products.map((product) => ({
          id: product.id,
          name: product.name,
        }));

        // Update how we handle brands:
        // If response.data.brands is an object like { 21: "Destino", 22: "Alibabet", ... }
        this.brands = Object.entries(response.data.brands).map(([id, name]) => ({
          id: parseInt(id), // Ensure it's an integer (or use string if needed)
          name,
        }));

      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    // async fetchBrands() {
    //   try {
    //     const response = await this.$axios.request({
    //       method: "GET",
    //       url: "/brands",
    //     });
    //
    //     // this.brands = response.data.map((brand) => ({
    //     //   id: brand.id,
    //     //   name: brand.name,
    //     // }));
    //   } catch (error) {
    //     console.error("Error fetching brands:", error);
    //   }
    // },
    async fetchBanners() {
      try {
        const response = await this.$axios.request({
          method: "GET",
          url: "/media_library",
        });
        // Assuming the response structure is { data: { banners: [...] } }
        this.banners = response.data.map((banner) => ({
          id: banner.id,
          name: banner.name
        }));
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    },
    async fetchSources() {
      try {
        const response = await this.$axios.request({
          method: "GET",
          url: "/source/index",
        });

        this.sources = response.data.sources.map((source) => ({
          id: source.id,
          name: source.name,
          url: source.url,
        }));
        console.log(typeof this.sources);
      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    },
    copyCode() {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Use the clipboard API if available
        navigator.clipboard.writeText(this.generatedCode)
            .then(() => {
              this.$toast.success("HTML Code Copied to Clipboard");
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
              this.$toast.error("Failed to copy code. Please try again.");
            });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = this.generatedCode;
        textarea.style.position = "fixed"; // Prevent scrolling to the bottom
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand("copy");
          this.$toast.success("HTML Code Copied to Clipboard");
        } catch (err) {
          console.error("Fallback copy failed: ", err);
          this.$toast.error("Failed to copy code. Please try again.");
        }
        document.body.removeChild(textarea);
      }
    },
    stepClass(i) {
      return this.step === i
          ? "font-bold text-xl"
          : this.step > i
              ? "text-gray-500 font-semibold"
              : "text-gray-400";
    },
    leave(el, done) {
      // Example: Fade out with a transition
      el.style.transition = 'opacity 1s';
      el.style.opacity = 0;
      done();
    },
    enter(el, done) {
      el.offsetHeight;  // Trigger a reflow to restart the transition
      el.style.transition = 'opacity 1s';
      el.style.opacity = 1;  // Fade in
      done();
    },
    beforeEnter(el) {
      el.style.opacity = 0;  // Initially hide the element
    },
  },
  async mounted() {
    await this.fetchProducts();
    await this.fetchBanners();
    await this.fetchSources();
  },
};
</script>

<style scoped>
.step-box {
  background-color: #f8fafc;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.step-select {
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.step-select:hover {
  border-color: #fd7e14;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
}

.step-indicator span {
  font-size: 1.1rem;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ffffff;

  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.banner-preview {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
</style>
