<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Add a New Source</h2>
        <form @submit.prevent="handleAddSource">
          <div class="mb-4">
            <input
                type="text"
                v-model="name"
                placeholder="Source Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="url"
                placeholder="Source URL"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="flex justify-center mt-6">
            <button
              :disabled="isSubmitting"
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
              >
              Add Source
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      name: '',
      url: '',
      isSubmitting: false, // To track submission state
    };
  },
  methods: {
    // Handle form submission
    async handleAddSource() {
      // If submitting, do not proceed
      if (this.isSubmitting) return;

      this.isSubmitting = true; // Set submitting state to true
      try {
        let response = await this.$axios.request({
          url: '/source/index',
          method: 'POST',
          data: {
            name: this.name,
            url: this.url,
          },
        });

        // Handle successful response (optional: do something with the response)
        console.log("Source added:", response.data);

        // Reset fields after successful submission
        this.name = '';
        this.url = '';
        this.isSubmitting = false; // Reset submitting state

        // Optionally show a success message to the user
        this.$toast.success('Source added successfully!');
      } catch (error) {
        // Handle error response
        console.error("Error adding source:", error);
        this.isSubmitting = false; // Reset submitting state

        // Optionally show an error message to the user
        this.$toast.error('An error occurred while adding the source.');
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>
