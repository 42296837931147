<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Add a New Product</h2>
        <form @submit.prevent="createProduct">
          <div class="mb-4">
            <input
                type="text"
                v-model="productId"
                placeholder="Product ID#"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="productName"
                placeholder="Product Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="contactNumber"
                placeholder="Contact Number"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="productDescription"
                placeholder="Product Description"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <select
                v-model="brandName"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="" disabled selected>Select Brand</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="flex justify-center mt-6">
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4">
              Add Product
            </button>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: {DashboardLayout, ButtonComponent },
  data() {
    return {
      brands: [],
      productId: '',
      productName: '',
      productDescription: '',
      brandName: '',
      contactNumber: '',
      emailError: '',
    };
  },
  methods: {
    validateEmail() {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
      this.emailError = !pattern.test(this.email) ? 'Please enter a valid email address.' : '';
    },
    async createProduct(){
      try {
        const response = await this.$axios.request({
          url: '/products',
          method: 'POST',
          data: {
            productId: this.productId,
            productName: this.productName,
            productDescription: this.productDescription,
            contactNumber: this.contactNumber,
            brandId: this.brandName,
          }
        });
        if(response.data.status === 'success') this.$toast.success('Product created successfully');

        console.log('Product created:', response.data);
        //Reset the form
        this.productId = '';
        this.productName = '';
        this.productDescription = '';
        this.brandName = '';
      } catch (error) {
        console.error('Error creating product:', error);
      }
    },
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: '/brands/id',
        });
        this.brands = response.data;
        console.log('Brands:', this.brands);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    handleAddProduct() {
      if (!this.emailError) {
        console.log("Adding Product:");
        console.log("Product ID:", this.productId);
        console.log("Product Name:", this.productName);
        console.log("Brand Name:", this.brandName);
        console.log("Email:", this.email);
        console.log("Contact Number:", this.contactNumber);

        // Reset fields
        this.productId = '';
        this.productName = '';
        this.brandName = '';
        this.email = '';
        this.contactNumber = '';
        this.emailError = '';

        this.$emit('close');
      }
    },
  },
  created() {
    this.fetchBrands();
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>
