<template>
  <DashboardLayout>
    <div class="flex flex-col">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <h2 class="text-2xl text-center font-bold text-orange-500 mb-8">Media Library</h2>
            <div class="mb-4 flex justify-between items-center gap-4">
              <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search by banner name"
                  class="p-2 rounded bg-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div v-for="(banner) in filteredBanners" :key="banner.id" class="bg-gray-200 rounded-lg overflow-hidden shadow-md">
                <img :src="'https://affiliateapi.web24.me' + banner.imageUrl" alt="Banner" class="w-full h-32 object-contain" />
                <div class="p-4">
                  <h3 class="font-semibold text-lg">{{ banner.name }}</h3>
                  <p class="text-sm text-gray-600">{{ banner.description }}</p>
                  <div class="flex justify-between mt-4 gap-6">
                    <button
                        @click="editBanner(banner)"
                        class="w-full material-icons flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                    >
                      edit
                    </button>
                    <button
                        @click="deleteBanner(banner.id)"
                        class="w-full material-icons flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-800 focus:outline-none mt-4"
                    >
                      delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade">
        <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white rounded-lg shadow-lg z-10 p-6 max-w-md w-full">
            <h2 class="text-2xl font-bold text-orange-500 mb-4">Edit Banner</h2>
            <form @submit.prevent="handleAddBanner">
              <div class="mb-4">
                <input
                    type="text"
                    v-model="newBanner.name"
                    placeholder="Banner Name"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-4">
                <input
                    type="file"
                    v-show="!newBanner.imageUrl"
                    @change="handleFileChange"
                    placeholder="Banner Image URL"
                    class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <div v-if="isValidImageUrl(newBanner.imageUrl)" class="mt-4">
                  <img :src="newBanner.imageUrl" alt="Banner Preview" class="w-full h-32 border object-contain rounded bg-form-100 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" />
                </div>
                <div v-else class="text-red-500 mt-2" v-show="!newBanner.imageUrl && newBanner.imageUrl !== ''">
                  Invalid image URL
                </div>
                <button
                    v-if="newBanner.imageUrl"
                    @click.prevent="clearImage"
                    class="mt-4 text-sm text-red-500 material-icons"
                >
                  clear
                </button>
              </div>
              <div class="mb-4">
                <textarea
                    v-model="newBanner.description"
                    placeholder="Banner Description"
                    class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                ></textarea>
              </div>
              <div class="flex justify-center mt-6">
                <ButtonComponent
                    type="submit"
                    class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                >
                  Save Changes
                </ButtonComponent>
              </div>
            </form>
            <ButtonComponent @click="closeModal" class="mt-4 text-red-500">
              Cancel
            </ButtonComponent>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import axios from 'axios';
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      domain: '/',
      isModalOpen: false,
      banners: [],
      newBanner: { name: '', imageUrl: '', description: '' },
      searchQuery: '',
    };
  },
  computed: {
    filteredBanners() {
      return this.banners.filter(banner =>
          banner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    async fetchBanners() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/media_library'
        });
        // Assuming response.data is an array of banners
        this.banners = response.data.map(banner => ({
          ...banner,
          imageUrl: banner.src_path  // Ensure correct image URL format
        }));
      } catch (error) {
        this.$toast.error('Failed to fetch banners');
      }
    },
    handleAddBanner() {
      if (this.newBanner.id) {
        const index = this.banners.findIndex(b => b.id === this.newBanner.id);
        if (index !== -1) {
          this.banners[index] = { ...this.newBanner };
        }
      } else {
        this.banners.push({
          id: this.banners.length + 1,
          ...this.newBanner
        });
      }
      this.closeModal();
    },
    editBanner(banner) {
      this.newBanner = { ...banner };  // Populate the form with existing banner data
      this.isModalOpen = true;  // Open the modal for editing
    },
    clearImage() {
      this.newBanner.imageUrl = '';
    },
    async deleteBanner(id) {
      try {
        await this.$axios.request({
          method: 'DELETE',
          url: `/media_library/index/${id}`
        });
      } catch (error) {
        console.error(error);
      }



      this.banners = this.banners.filter(banner => banner.id !== id);
    },
    resetForm() {
      this.newBanner = { name: '', imageUrl: '', description: '' };
    },
    isValidImageUrl(url) {
      // Simple URL validation for images
      return url && (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif'));
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.newBanner.imageUrl = URL.createObjectURL(file);
      }
    }
  },
  created() {
    this.fetchBanners();
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

img {
  object-contain: cover; /* Ensures the image fits its container without stretching */
}
</style>